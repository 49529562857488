/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import routesEN from "routesEN";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import '../../style.css'
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import BlogPostTwo from "pages/presentation/sections/Blog";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import Logo from "./sections/Logos"
// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import CountersBis from "pages/presentation/sections/CountersBis";
import Information from "pages/presentation/sections/Information";
import DesignBlocks from "pages/presentation/sections/DesignBlocks";
import AuthPages from "pages/presentation/sections/AuthPages";
import Pages from "pages/presentation/sections/Pages";
import Testimonials from "pages/presentation/sections/Testimonials";
import Pricing from "pages/presentation/sections/Pricing";

// Presentation page components
import BuiltByDevelopers from "pages/presentation/components/BuiltByDevelopers";
import { useEffect, useRef, useState,useContext } from "react";
import { useMediaQuery } from 'react-responsive'
import { Context } from "../../Context";
import Contact from "./sections/Contact";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import {getArticles, getPages } from "../../hooks/airtable";

// Images
import bgImage from "assets/images/bg-presentation.jpg";

function Presentation() {
  const windowSize = useRef([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [contentData, setContentData] = useState([]);
  const {langContext, setLangContext} = useContext(Context);
  const [isFetch, setIsFetch] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  useEffect(() => {
    async function fetchData() {
      const data = await getPages();
      setContentData(data);
      setIsFetch(true)
    }
    fetchData();

  }, []);
  return (
    <>
    {isFetch ?
    <>
      <DefaultNavbar

        routes={langContext =="Français" ? routes : routesEN}

        action={{
          type: "external",
          route: "https://www.google.com",
          label: "buy now",
          color: "info",
        }}
        sticky
      />
      {/* <MKBox
        minHeight="55vh"
        width="100%"
        sx={{
         backgroundImage: `url(${'https://res.cloudinary.com/dbt1oxzwd/image/upload/v1713775954/Capture_d_e%CC%81cran_2024-04-22_a%CC%80_10.52.25_koauvg.png'})`,
        /// backgroundColor:'#4C4540',
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              textAlign="center" 
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Care 1{" "}
              <MKBadge
                badgeContent="EU"
                size="lg"
                variant="contained"
                color="white"
                container
                sx={{ mt: -4 }}
              />
            </MKTypography>
            <MKTypography variant="body1" color="white" textAlign="center" px={6} mt={1}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed consectetur adipiscing elit, sed 
            </MKTypography>
          </Grid>
        </Container>
      </MKBox> */}
      <div  style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
      <div style={{height:450}}>
    <div id="bg-wrap">
  <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
  <defs>
  <radialGradient id="Gradient1" cx="50%" cy="50%" fx="0.441602%" fy="50%" r=".5"><animate attributeName="fx" dur="34s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="#C0D435"></stop><stop offset="100%" stop-color="rgba(192, 212, 53, 0)"></stop></radialGradient>
  <radialGradient id="Gradient2" cx="50%" cy="50%" fx="2.68147%" fy="50%" r=".5"><animate attributeName="fx" dur="23.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="#E0592A"></stop><stop offset="100%" stop-color="rgba(224, 89, 42, 0)"></stop></radialGradient>
  <radialGradient id="Gradient3" cx="50%" cy="50%" fx="0.836536%" fy="50%" r=".5"><animate attributeName="fx" dur="21.5s" values="0%;3%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="#EDEDEC"></stop><stop offset="100%" stop-color="rgba(228,230,231, 0)"></stop></radialGradient>
  <radialGradient id="Gradient4" cx="50%" cy="50%" fx="4.56417%" fy="50%" r=".5"><animate attributeName="fx" dur="23s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="#C0D435"></stop><stop offset="100%" stop-color="rgba(192, 212, 53, 0)"></stop></radialGradient>
  <radialGradient id="Gradient5" cx="50%" cy="50%" fx="2.65405%" fy="50%" r=".5"><animate attributeName="fx" dur="24.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="#E0592A"></stop><stop offset="100%" stop-color="rgba(224, 89, 42, 0)"></stop></radialGradient>
  <radialGradient id="Gradient6" cx="50%" cy="50%" fx="0.981338%" fy="50%" r=".5"><animate attributeName="fx" dur="25.5s" values="0%;5%;0%" repeatCount="indefinite"></animate><stop offset="0%" stop-color="#fff"></stop><stop offset="100%" stop-color="rgba(228,230,231, 0)"></stop></radialGradient>
  </defs>
<rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient4)">
  <animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite" />
  <animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite" />
  <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="17s" repeatCount="indefinite"/>
  </rect>
  <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient5)">
  <animate attributeName="x" dur="23s" values="0%;-25%;0%" repeatCount="indefinite" />
  <animate attributeName="y" dur="24s" values="25%;-25%;25%" repeatCount="indefinite" />
  <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="18s" repeatCount="indefinite"/>
  </rect>
  <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient6)">
  <animate attributeName="x" dur="25s" values="-25%;0%;-25%" repeatCount="indefinite" />
  <animate attributeName="y" dur="26s" values="0%;-25%;0%" repeatCount="indefinite" />
  <animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="19s" repeatCount="indefinite"/>
  </rect>
  <rect x="13.744%" y="1.18473%" width="100%" height="100%" fill="url(#Gradient1)" transform="rotate(334.41 50 50)"><animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="7s" repeatCount="indefinite"></animateTransform></rect>
  <rect x="-2.17916%" y="35.4267%" width="100%" height="100%" fill="url(#Gradient2)" transform="rotate(255.072 50 50)"><animate attributeName="x" dur="23s" values="-25%;0%;-25%" repeatCount="indefinite"></animate><animate attributeName="y" dur="24s" values="0%;50%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="12s" repeatCount="indefinite"></animateTransform>
  </rect>
  <rect x="9.00483%" y="14.5733%" width="100%" height="100%" fill="url(#Gradient3)" transform="rotate(139.903 50 50)"><animate attributeName="x" dur="25s" values="0%;25%;0%" repeatCount="indefinite"></animate><animate attributeName="y" dur="12s" values="0%;25%;0%" repeatCount="indefinite"></animate><animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="9s" repeatCount="indefinite"></animateTransform>
  </rect>
  </svg>
  <Card style={{position:'absolute',top:130,left:(windowSize.current[0]/2)-125}} sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor:  /* rgba('#E4E6E7', 0.8) */rgba(white.main, 0.7),
          backdropFilter: `saturate(200%) blur(30px)`,
        })}>

    <img width="250px" height="170px" style={{marginTop: "20px",marginBottom: "20px"}} src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1713481950/Capture_d_e%CC%81cran_2024-04-19_a%CC%80_00.15.52-removebg-preview_iymrmu.png"}/>
  </Card>
</div>
      </div>
      <Container>
          <Grid
            container
            spacing={{ xs: 0, lg: 0 }}
            sx={{ mt: { xs: 0, lg: 0 } }}
            flexDirection="row"
            style={{display:'flex',justifyContent:'center',flexDirection:'row'}}
          >
            <Grid item xs={12} lg={8} justifyContent="center" flexDirection="column">
              <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                shadow="lg"
                borderRadius="xl"
                style={{ borderWidth: "3px" }}
                sx={{
                  backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                    rgba('#fff', 0.5),
                  backdropFilter: "saturate(200%) blur(30px)",
                  px: { xs: 3, sm: 0 },
                  py: { xs: 3, sm: 6 },
                  mb: { xs: 0, lg: 14 },
                  mt: { xs: 0, lg: -6 },
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  md={10}
                  justifyContent={{ xs: "center", md: "center" }}
                  sx={{ textAlign: { xs: "center", md: "center" } }}
                >

                  <MKTypography
                    variant="h4"
                    mt={0}
                    // pr={{ md: 12, lg: 24, xl: 32 }}
                    opacity={0.8}
                  >
                    <span className='font-face-db' style={{ paddingBottom: "20px" }}>
                      {contentData.find(item => item.fields.key === "Presentation " + langContext && item.fields.Page === "Accueil").fields.Titre}
                    </span>
                    </MKTypography>
                    <MKTypography
                    variant="body2"
                    mt={0}
                    // pr={{ md: 12, lg: 24, xl: 32 }}
                    opacity={0.8}
                  >
                    <span className='font-face-med-2'>
                    {contentData.find(item => item.fields.key === "Presentation " + langContext && item.fields.Page === "Accueil").fields.Texte}

                    </span>
                    <br />
                  </MKTypography>
                </Grid>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
        </div>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.2),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        {/* <CountersBis /> */}
        <Grid item style={{justifyContent:'center',width:'100%',display:'flex',justifyContent:'center',flexDirection:'row',marginTop:35}}>
          <MKBox component="img" style={{borderRadius:10}} src={contentData.find(item => item.fields.key === "Section 2 - Main " + langContext && item.fields.Page === "Accueil").fields.Image[0].url} alt="bio-me" width="50%" opacity={0.9} />
        </Grid>
        
       {/*  <Information /> */}
       {/*  <DesignBlocks /> */}
       {/*  <AuthPages />
        <Pages /> */}
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>

        {isFetch ?
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <FilledInfoCard
                variant="gradient"
                color="warning"
                icon="flag"
                title={contentData.find(item => item.fields.key === "Section 2 - Sec 1 " + langContext && item.fields.Page === "Accueil").fields.Titre}
                description={contentData.find(item => item.fields.key === "Section 2 - Sec 1 " + langContext && item.fields.Page === "Accueil").fields.Texte}
                action={{
                  type: "external",
                  route: "https://www.google.com",
                  label: langContext =='Anglais' ? 'See more' : 'Voir plus',
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
              variant="gradient"
                color="success"
                icon="precision_manufacturing"
                title={contentData.find(item => item.fields.key === "Section 2 - Sec 2 " + langContext && item.fields.Page === "Accueil").fields.Titre}
                description={contentData.find(item => item.fields.key === "Section 2 - Sec 2 " + langContext && item.fields.Page === "Accueil").fields.Texte}
                action={{
                  type: "external",
                  route: "https://www.google.com",
                  label: langContext =='Anglais' ? 'See more' : 'Voir plus',
                }}
              />
            </Grid>
            {/* <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="warning"
                icon="apps"
                title="Titre 3"
                description="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae "
                action={{
                  type: "external",
                  route: "https://www.google.com",
                  label: "Voir plus",
                }}
              />
            </Grid> */}
          </Grid>
        </Container>
        :null}
        <Testimonials title={contentData.find(item => item.fields.key === "Article " + langContext && item.fields.Page === "Accueil").fields.Titre} subtitle={contentData.find(item => item.fields.key === "Article " + langContext && item.fields.Page === "Accueil").fields.Texte} />
        {/* <Pricing /> */}
        <BlogPostTwo/>
        
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:40,marginBottom:-50}}>
        <MKTypography
              variant="h2"
              color="dark"
              className='font-face-db'
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
          Agenda
        </MKTypography>
        <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column" style={{marginTop:40}}>

        
        <section id="roadmap" class="container">

            <div class="row justify-content-center">
                <div class="col col-lg-10">
                    <div class="roadmap">
                        <div class="rcontainer">
                            <div class="roadmap-wrapper">
                                <div class="roadmap-item-wrap left scrollflow ">
                                    <div class="roadmap-item left">
                                        <h5 class="list-title">May 2023</h5>
                                        <ul class="roadmap-list">
                                            <li>{langContext === "Français" ? "Début du projet":"Project start date"}</li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="roadmap-item-wrap right scrollflow ">
                                    <div class="roadmap-item right">
                                        <h5 class="list-title">April 2024 in France</h5>
                                        <ul class="roadmap-list">
                                        <li>{langContext === "Français" ? "Début de l’étude clinique":"Start of the trial"}</li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="roadmap-item-wrap left scrollflow ">
                                    <div class="roadmap-item left">
                                        <h5 class="list-title">May 2024</h5>
                                        <ul class="roadmap-list">
                                        <li>{langContext === "Français" ? "Première Assemblée Générale #1":"First General Assembly #1"}</li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="roadmap-item-wrap right scrollflow ">
                                    <div class="roadmap-item right">
                                        <h5 class="list-title">September 2024</h5>
                                        <ul class="roadmap-list">
                                        <li>{langContext === "Français" ? "Début de l’étude dans la République Tchèque":"Trial start in Czech Republic"}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="roadmap-item-wrap left scrollflow ">
                                    <div class="roadmap-item left">
                                        <h5 class="list-title">TBA</h5>
                                        <ul class="roadmap-list">
                                        <li>{langContext === "Français" ? "Début de l’étude aux Pays-Bas, l’Autriche, le Royaume-Uni, l’Italie et l’Allemagne":"Trial start in Netherlands, Austria, United Kindgom, Italy and Germany"}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="roadmap-item-wrap right scrollflow ">
                                    <div class="roadmap-item right">
                                        <h5 class="list-title">May 2025</h5>
                                        <ul class="roadmap-list">
                                        <li>{langContext === "Français" ? "Seconde Assemblée Générale #2":"Second General Assembly #2"}</li>

                                        </ul>
                                    </div>
                                </div>
                                <div class="roadmap-item-wrap left scrollflow ">
                                    <div class="roadmap-item left">
                                        <h5 class="list-title">May 2026</h5>
                                        <ul class="roadmap-list">
                                        <li>{langContext === "Français" ? "Troisième Assemblée Générale #3":"Third General Assembly #3"}</li>

                                        </ul>
                                    </div>
                                </div>

                                <div class="roadmap-item-wrap right scrollflow ">
                                    <div class="roadmap-item right">
                                        <h5 class="list-title">May 2027</h5>
                                        <ul class="roadmap-list">
                                        <li>{langContext === "Français" ? "Quatrième Assemblée Générale #4":"Fourth General Assembly #4"}</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </Grid>
        </div>

        <div style={{marginTop:80}}>
        <Logo/>
        </div>
        <Contact/>
        <MKBox pt={8} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                   {langContext === "Français" ? "Suivez nous sur les réseaux":"Follow us on social media"}
                </MKTypography>
                {/* <MKTypography variant="body1" color="text">
                 Description
                </MKTypography> */}
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://www.linkedin.com/company/care1-pragmatic-clinical-trial/"
                  target="_blank"
                  color="linkedin"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-linkedin" />
                  &nbsp;Linkedin
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/profile.php?id=61553025358693"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Facebook
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://x.com/CARE1project"
                  target="_blank"
                  color="twitter"
                >
                  <i className="fab fa-twitter" />
                  &nbsp;Twitter X
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
    :null}</>
  );
}

export default Presentation;
